@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.activation-tags {
  .containerField {
    @include containerField;
    
    &.picture img {
      object-fit: cover;
    }

  }

  h1, label, p, textarea {
    color:$black !important;
    span {
      color: $black !important
    }
  }

  .notations {
    flex: 1;
    margin-top: 30px;
    padding-bottom: 30px;
    .notation {
      margin-bottom: $margin-sm;
      label {
        display: inline-block;
        margin-right: 20px;
        font-family: 'CenturyGothic-Bold';
        font-size: $regular;
        text-transform: uppercase;
      }
      .note {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        padding-left: 20px;
        padding-right: 20px;;
        border: solid 1px #E5E5E5;
        border-radius: 4px;
        color: $text-color;
        transition: all 0.3s ease;
        cursor: pointer;

        &.selected {
          background-color: #00516E;
          p {
            color: $white !important;
          }
        }

        &:not(:last-child) {
          margin-right: $margin-sm;
        }
        p {
          margin: 0;
        }
      }
    }
  }

  .separator {
    height: 1px;
    display: block;
    width: 100%;
    background-color: #E5E5E5;
  }
  
}
