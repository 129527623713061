@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.activation {
  display: flex;
  height: 100%;

  p {
    margin: 0 0 $margin-lg 0;
  }

  .back{
    color:$white;
  }

  .sidebar {
    height: calc(100% - 50px);
    margin-top: 50px;
    width: 40%;
    display: block;
    overflow-y: auto;
    padding-right: 20px;

    @include sidebarContainerTitle();

    .containerTitle{
      padding: 0 0 0 50px;
      h1{
        font-size: 40px;
        margin-top: 30px;
      }
    }

    .containerTags {
      display: flex;
      flex-direction: row;
      margin-left: 50px;
      margin-bottom: 50px;
  
      .containerTag {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C9A65C;
        color: $white;
        padding: 5px 10px;
        border-radius: 4px;
        margin-right: 10px;
        font-size: 12px;
        font-family: 'CENTURYGOTHIC';
      }
    }

  }

  .container {
    position: relative;
    overflow-y: auto;
    margin-left: 50px;
    scroll-behavior: smooth;
    
    .toggle {
      border-bottom: solid 1px rgba(255, 255, 255, 0.2);
      margin-bottom: 10px;
      h4{
        display: inline-block;
      }
      .icon{
        float: right;
        line-height: 28px;
        width: 28px;
        text-align: center;
        cursor: pointer;
        color: #C9A65C;
        font-size: 20px;
      }
    }

    .content {
      @include content();
      will-change: opacity transform;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: calc(100% - $header-height);
      margin-top: $margin-lg;
      margin-bottom: $margin-lg;
    }

  }

  .kpis {
    height: calc(100% - 70px);
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 70px;
    padding: 20px 20px 20px 0;
    overflow: auto;
  }

  .toolbar {
    position: absolute;
    top: 30px;
    right: 30px;
    .exportActivation{
      height: 36px;
      margin-left: 10px;
      background: transparent;
      border-color: rgba(255, 255, 255, 0.5);
      vertical-align: top;
    }
    .duplicateActivation{
      height: 36px;
      margin-left: 10px;
      background: transparent;
      border-color: rgba(255, 255, 255, 0.5);
      vertical-align: top;
      img{
        margin-top: -4px;
      }
    }
  }

}