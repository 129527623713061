@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.moodboard {

  .append {
    background-color: transparent;
    color: $black;
    padding-left: 0;
    border:none;
    margin-bottom: $margin-sm;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    span {
      position: relative;
      top: -4px;
    }
    .icon {
      display: inline-block;
      background-color: $black;
      border-radius: 3px;
      margin-left: $margin-sm;
      font-size: $h4;
      color: $white;
      padding: 5px;
      border:none;
      line-height: 10px;
    }
  }


  .grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: $margin-md;
    margin-bottom: $margin-md;
    max-height: 500px;

    &.grid1 {
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    &.grid2 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    &.grid3 {
      >div:first-child { grid-area: 1 / 1 / 2 / 3; }
      >div:nth-child(2) { grid-area: 1 / 3 / 2 / 7; }
      >div:nth-child(3) { grid-area: 2 / 1 / 3 / 7; }
    }

    &.grid4 {
      >div:first-child { grid-area: 1 / 1 / 2 / 3; }
      >div:nth-child(2) { grid-area: 1 / 3 / 2 / 5; }
      >div:nth-child(3) { grid-area: 1 / 5 / 2 / 7; }
      >div:nth-child(4) { grid-area: 2 / 1 / 3 / 7; }
    }

    &.grid5 {
      >div:first-child { grid-area: 1 / 1 / 2 / 3; }
      >div:nth-child(2) { grid-area: 1 / 3 / 2 / 5; }
      >div:nth-child(3) { grid-area: 1 / 5 / 2 / 7; }
      >div:nth-child(4) { grid-area: 2 / 1 / 3 / 4; }
      >div:nth-child(5) { grid-area: 2 / 4 / 3 / 7; }
    }

    &.grid6 {
      >div:first-child { grid-area: 1 / 1 / 2 / 3; }
      >div:nth-child(2) { grid-area: 1 / 3 / 2 / 5; }
      >div:nth-child(3) { grid-area: 1 / 5 / 2 / 7; }
      >div:nth-child(4) { grid-area: 2 / 1 / 3 / 3; }
      >div:nth-child(5) { grid-area: 2 / 3 / 3 / 5; }
      >div:nth-child(6) { grid-area: 2 / 5 / 3 / 7; }
    }

    >div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $radius;
      // max-height: 100px;

      // container media_img
      >div {
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
          // max-height: 330px;
        }
      }
    }
  }
}
