@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.activations {
  height: 100%;
  overflow-y: scroll;
  padding-top: $header-height;

  .content .banner {
    @include content();

    display: flex;
    align-items: center;
    padding: $margin-md 0;
    margin: $margin-lg auto;
    max-width: 100%;

    .containerField {
      margin-right: 10px;;
    }

    .createNewActivation {
      margin-left: auto;
    }
    .exportActivation {
      height: 36px;
      margin-left: 10px;
      background: transparent;
      border-color: rgba(255,255,255,0.5);
    }
  }

  .content .activations-list {
    overflow-x: auto;
    overflow-y: hidden;
    width: calc(100% - #{$margin-lg * 2});
    margin-left: $margin-lg;
    .activation {
      width: max-content;
    }
    .activation-col-separator {
      width: 1px;
      height: 45px;
      background-color: rgba(255,255,255,0.2);
      display: inline-block;
      margin-right: 20px;
      margin-left: 20px;
      vertical-align: bottom;
    }
    .activation-col, .activation-col-filter {
      display: block;
      &:first-child {
        width: 260px;
      }
      height:42px;
      line-height: 42px;
      width: 130px;
      display: inline-block;
      p {
        margin: 0;
        font-family: 'CenturyGothic';
        font-size: $medium;
        line-height: normal;
      }
      .separator {
        @include content();
        &:after {
          content: '';
          display: block;
          border-top: solid 1px $black;
        }
      }
    }
    .activation-col-filter{
      width: 160px;
      line-height: normal;
      padding-right: 10px;
    }
    .separator {
      @include content();
      &:after {
        content: '';
        display: block;
        border-top: solid 1px $black;
      }
    }
  }

}