@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.edit {
  display: flex;
  height: 100%;
  background-color: #fff;
  
  label {
    display: block;
    font-family: 'CenturyGothic';
  }

  .sidebar {
    height: 100%;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    background-color: #EAF0F2;

    @include sidebarContainerTitle();

    button {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    input {
      color: $black;
    }
  }

  .container {
    flex: 1;
    overflow-y: auto;
    margin-top: $header-height;
    .form {
      @include content();
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: calc(100% - $header-height);
      margin-top: $margin-lg;
      margin-bottom: $margin-lg; 
    }

  }

  .containerLoader{
    position: absolute !important;
    display: block !important;
    top: 30px !important;
    right: 140px !important;
  }

}