@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 50px;
  margin-top: 30px;
  p{
    font-size: $regular;
    color: #091C33
  }
  a {
    position: relative;
    font-family: 'CenturyGothic-Bold';
    font-size: $medium;
    color: $text-color-light;
    padding: 18px $margin-md 18px $margin-md;
    border-radius: 5px;
    &.active {
      color: $text-color;
      background-color: #00516E;
    }
    &.inactive {
      color: rgba(9, 28, 51, 0.3);
      cursor: not-allowed;
    }
  }
}