@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.header {
  position: fixed;
  width: 50%;
  z-index: 3;
  padding: 0 $margin-md;

  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    height: $header-height;
    overflow: hidden;

    h1 {
      margin:0
    }

  }
}