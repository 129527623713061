@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.reset-password {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  .container {
    position: relative;
    z-index: 1;
    padding: $margin-lg;
    @include content();
    display: flex;
    align-items: center;
    justify-content: space-between;

    // >div {
    //   flex: 1;
    // }

    >div:first-child {
      width: 40%;
    }

    .containerImage {
      .logo {
        position: absolute;
        z-index: 1;
        top: 50%;
        width: 650px;
        transform: translate(-20%, -50%);
      }
    }

    h3 {
      font-family: "CenturyGothic-Bold";
      color: $text-color;
      margin: -50px auto $margin-md auto;
      max-width: 400px;
      width: 100%;
    }
  }
}