@import '../../../styles/_variables.scss';
@import '../form-mixin.scss';

.container-input-password{
  position: relative;

  &:not(.login) {
    label{
      @include label;
    }
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.container-input {
      margin-top: 10px;
    }
  }

  .input {
    @include input;
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
    @include label-secondary;
  }

}

.container-input{
  position: relative;
  width: 100%;

  .container-icon{
    display: block;
    position: absolute;
    justify-content: center;
    align-content: center;
    top:0;
    right:0;
    height: 39px;
    width: 39px;
    cursor: pointer;

    >svg{
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
    }
  }

}

.container-input .input {

  &::-ms-reveal,
  &::-ms-clear{
    display: none;
  }

  // HIDE KEY ON SAFARI
  &::-webkit-credentials-auto-fill-button {
    margin: 0;
    width: 0;
    background-color: transparent;
  }
  
}
