@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.moodboard {

  .containerField {
    @include containerField;
  }

  h1, p, input {
    color:$black !important;
    span {
      color: $black !important
    }
  }

  .separator {
    height: 1px;
    display: block;
    width: 100%;
    background-color: #E5E5E5;
  }

  .videos {
    margin-bottom: $margin-lg;

    .containerField {
      display: flex;
      width: 100%;
      margin-bottom: $margin-sm;
      border-bottom: solid 1px $grey;
  
      input {
        width: 100%;
        border: none;
        padding-left: 0;
        font-family: 'CenturyGothic';
      }
      >div:first-child {
        flex: 1;
      }
      
      .remove {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 40px;
        font-size: 20px;
        border:none;
        cursor: pointer;
      }
    }

    .append {
      background-color: transparent;
      color: $black;
      padding-left: 0;
      border:none;
      span {
        position: relative;
        top: -4px;
      }
      .icon {
        display: inline-block;
        background-color: $black;
        border-radius: 3px;
        margin-left: $margin-sm;
        font-size: $h4;
        color: $white;
        padding: 5px;
        border:none;
        line-height: 10px;
      }
    }
  }

  .gridTwo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-md;
    margin-top: $margin-md;

    label {
      position: relative;
      display: block;
      z-index: 2;
      background-color: $black;
      color: $white;
      width: 120px;
      margin: 0 auto 0 auto;
      text-align: center;
      padding: $margin-sm;
      border-radius: $radius;
      +.containerField {
        position: relative;
        z-index: 1;
        margin-top: -20px;
      }
    }

  }

}