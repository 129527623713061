@import '../../../../styles/variables';

.resetpwd-form{
  max-width: 400px;
  .form{
    // margin-bottom: $margin-lg;
    .container-field{
      margin: 0 0 15px 0;
    }
    .message-error{
      color : $warn;
      font-size: 12px;
      margin-top: $margin-sm;
    }
    .message-succeed{
      font-family: 'CenturyGothic';
      color : $secondary-color;
      font-size: $regular;
    }

    .succeed,
    .submit {
      margin-top: $margin-lg;
    }

    button.btnSucceed{
      max-width: 100%;
      white-space: nowrap;
      margin-bottom: $margin-sm;
    }

  }

}
