@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.investment {

  .containerField {
    @include containerField;
  }

  h1, label, p, input {
    color:$black !important;
    span {
      color: $black !important
    }
  }

  .col-label{
    color:rgba(9,28,51,0.4) !important;
  }

  .separator {
    height: 1px;
    display: block;
    width: 100%;
    background-color: #E5E5E5;
  }

  .investment-line{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
    p {
      margin: 0;
    }
    .investment-col {
      display: flex;
      margin-right: 20px;
      width: 150px;
      &:first-child {
        width: 150px;
      }
    }
  }

}