@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.kpis {
  .kpi-line{
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 32px;
    height: 32px;
    background-color: rgba(255, 255, 255, 0.2);
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    padding-left: 10px;
    padding-right: 10px;
    .kpi-col {
      display: flex;
      margin-right: 20px;
      width: 30%;
      justify-content: center;
      &:first-child {
        width: 40%;
        justify-content: left;
      }
    }
    p{
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .subtitle {
      font-size: 14px;
      margin-bottom: 0;
      margin-top: 10px;
      font-family: 'CENTURYGOTHIC-BOLD';
    }
  }
  .kpi-line-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 32px;
    height: 32px;
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    
    &:first-child {
      border-radius: 10px 10px 0 0;
    }

    p{
      color: $black;
      font-family: 'CENTURYGOTHIC-BOLD';
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .kpi-col {
      display: flex;
      margin-right: 20px;
      width: 30%;
      justify-content: center;
      &:first-child {
        width: 40%;
        justify-content: left;
      }
    }
  }
  p {
    margin: 0 !important;
  }
}
