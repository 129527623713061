@import '../../../styles/variables.scss';
@import '../../../styles/mixins';
@import '../form-mixin.scss';

.container {
  width: 100%;
  position: relative;
  label {
    @include label;
  }

  label + .img {
    margin-top: 10px;
  }

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    border-radius: $radius;
    // background-color: rgba($black , 0.5);
    box-shadow: inset 0px 0px 0px 1px $black;
    position: relative;
    overflow: hidden;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      box-shadow: inset 0px 0px 0px 1px $text-color-light;
    }

    img , video {
      position: relative;
      z-index: 0;
      width: 100%;
      border-radius: $radius;
      object-position: center;
    }

    img {
      object-fit: contain;
      max-height: 200px;
    }

    video {
      object-fit: contain;
      max-height: 200px;
    }

  }
  .btn-container {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: 10px;
    right: 10px;
    button {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      max-width: 30px;
      max-height: 30px;
      border-radius: 15px;
      z-index: 200;
      padding: 0px;
      color: $white;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 8px;
      }
      // &:hover {
      //   background-color: $primary-color;
      //   color: $black;
      // }
    }
  }
  .input {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.disabled label{
      opacity: 0.3;
    }

    label {
      padding: 10px $margin-sm;
      border-radius: $radius;
      border: 0;
      background-color: transparent;
    
      outline: none;
      cursor: pointer;
      font-family: 'CenturyGothic-Bold';
      font-size: $small;
      color: $white;
      background-color: $black;
      border: 1px solid transparent;
    }
    input {
      display: none;
    }
  }
  .edit {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 0px;
    overflow: hidden;
    z-index: 100;
    background-color: #f9f1f1;
    box-shadow: inset 0px 0px 0px 1px $black;
    &.active {
      height: 100px;
    }
    button.close {
      border: none;
      float: right;
      margin: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      // margin-left: 10px;
      border-radius: 15px;
      background-color: transparent;
      color: $black;
      padding: 0px;
      &:hover {
        color: $primary-color;
      }
    }


    .form {
      padding: 10px 0px 10px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      >label {
        display: block;
        flex: 1;
        min-width: 100%;
      }

      .field {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        >div {
          flex: 1;
        }

        .https {
          font-family: 'CenturyGothic-Bold';
          color: $text-color-light;
          margin-right: $margin-xs;
        }

      }
      button.submit {
        cursor: pointer;
        border: none;
        background-color: transparent;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        padding: 0px;
        color: $black;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}