@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.signin {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  .container {
    position: relative;
    z-index: 1;
    padding: $margin-lg;
    @include content();
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.sso {
      justify-content: center;
    }

    // >div {
    //   flex: 1;
    // }

    >div:first-child {
      width: 40%;
    }

    >div:nth-child(2) {
      height: 100%;
    }

    .containerImage {
      .logo {
        position: absolute;
        z-index: 1;
        top: 50%;
        width: 650px;
        transform: translate(-20%, -50%);
      }
      img:nth-child(2) {
        max-height: 650px;
      }

      a {
        position: absolute;
        top: calc(50% + 55px);
        left: calc(50% - (450px / 2));
        @media screen and (min-width: 900px) {
          left: calc(50% - (650px / 2));
        }
        button {
          padding: $margin-md $margin-lg;
          font-size: $medium;
        }
      }

    }

    h3 {
      font-family: "CenturyGothic-Bold";
      color: $text-color;
      margin: -50px auto $margin-md auto;
      max-width: 400px;
      width: 100%;
    }
  }
}
