
@import './_variables.scss';

@mixin h1 {
  font-size: $h2;
  // line-height: calc($h2 + 2px);
  color: $text-color;
  font-weight: 600;

  @media screen and (min-width: $md-screen) {
    font-size: $h1;
    // line-height: calc($h1 + 2px);
  }
}

@mixin h2 {
  font-size: 30px;
  color: $text-color;
  text-transform: uppercase;
  font-weight: 600;

  @media screen and (min-width: $md-screen) {
    font-size: $h2;
  }
}

@mixin h4{
  font-size: 18px;
  color: $text-color;

  @media screen and (min-width: $md-screen) {
    font-size: $h4;
  }
}

@mixin h5 {
  font-size: $small;
  text-transform: uppercase;
  color: $text-color;
}

@mixin content {
  max-width: 1440px;
  width: 100%;
  padding: 0 $margin-sm;
  margin: 0 auto;

  @media screen and (min-width: $xs-screen) {
    padding: 0 $margin-md;
  }

  @media screen and (min-width: $sm-screen) {
    padding: 0 $margin-lg;
  }
}

@mixin content-min {
  max-width: 1240px;
  width: 100%;
  padding: 0 $margin-sm;
  margin: 0 auto;

  @media screen and (min-width: $xs-screen) {
    padding: 0 $margin-md;
  }

  @media screen and (min-width: $sm-screen) {
    padding: 0 $margin-lg;
  }
}

@mixin content-large {
  max-width: 1700px;
  width: 100%;
  margin: 0 auto;
}

@mixin containerField {
  margin-bottom: $margin-md;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($black, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $black;
  }
}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@mixin list() {
  margin: 0 0 $margin-xl 0;
  .item {
    margin: 0 0 $margin-sm 0;
    border-bottom: solid 1px $grey;
    p {
      margin: 0 0 $margin-sm 0;
    }
  }
}

@mixin fromTo() {
  .fromTo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >div {
      flex: 1;
    }

    p {
      white-space: nowrap;
      margin-bottom: 0;
      font-family: 'CenturyGothic-Bold';
      text-transform: uppercase;
    }

    .arrow {
      position: relative;
      top: 2px;
      flex: 1;
      height: 1px;
      background-color: $black;
      margin: 0 $margin-md;
      max-width: 300px;
      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0.5px;
        right: 0;
        width: 5px;
        height: 5px;
        border-top: solid 1px $black;
        border-right: solid 1px $black;
        transform-origin: 100% 0;
        transform: translateX(1px) rotate(45deg);
      }
    }
  }
}

@mixin sidebarContainerTitle() {
  .containerTitle {
    padding: 0 $margin-xl;
    // margin-bottom: $margin-lg;

    .back {
      margin-top: $margin-md;
      height: 32px;
      width: 32px;
      cursor: pointer;
    }

    h1 {
      margin-bottom: $margin-lg;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      +a button,
      +button {
        margin-bottom: $margin-lg;
      }

    }

    .containerLoader {
      position: relative;
      display: inline-flex;
      height: 20px;
      width: 30px;
      top: 6px;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
    }

  }
}