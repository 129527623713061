@import "../../styles/_variables.scss";

.loader {
  display: inline-block;
  position: relative;

  &:not(.white) div {
    border-color: $primary-color transparent transparent transparent;
  }

  &.white div {
    border-color: $white transparent transparent transparent;
  }

  &.small {
    width: 20px;
    height: 20px;
    position: relative;

    div {
      margin-left: -1px;
      margin-top: -1px;
      width: 20px;
      height: 20px;
      border-width: 2px;
    }
  }

  &:not(.small) {
    width: 60px;
    height: 40px;
    div {
      width: 60px;
      height: 60px;
    }
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 60px;
    height: 60px;
    margin: 6px;
    border: 6px solid $primary-color;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
