@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  label {
    @include label;
  }
  
  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
    }
  }
  
  input {
    appearance: none;
    padding: 0px;
    margin: 0px 0px 0px 20px;
    border-radius: 4px;
    border: none;
  }
  input[type="color"]::-moz-color-swatch {
    border: none;
  }
  
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 0;
  }
  
  input[type="color"]::-webkit-color-swatch {
    border: none;
  }
  
}
