@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-textarea {
  margin: 0px 0px 0px 0px;

  label {
    @include label;
  }

  &.login:not(.inline) label {
    background-color: $grey;
    color: $white;
  }

  &.inline {
    @include inline;
    align-items: flex-start;
    label {
      margin-top: 12px;
    }
  }

  &:not(.inline) {
    label + .container {
      margin-top: $margin-sm;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }


  .input {
    @include input;
    @include scrollbar;
    // padding-top: 10px;
    // padding-bottom: 10px;
    resize: none;
    font-size: $medium;
    font-family: 'CenturyGothic';
    border-radius: $radius;
    border: solid 1px #E5E5E5;

    &.disabled {
      pointer-events: none;
    }
  }

  &.primary {
    @include primary;
  }

  &.discret textarea {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border: none;
  }

  &.secondary {
    @include secondary;
    textarea {
      line-height: 18px !important;
      border: none !important;
    }
  }

  &.login {
    @include login;
  }

  .container {
    width: 100%;
    .count-characters {
      text-align: right;
      font-size: $regular;
      margin: 0;
      padding: $margin-sm $margin-sm 0 $margin-sm;
      color: $text-color;
      span {
        color: $text-color;
      }
    }
  }
}
