@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.activation {
  @include remove-highlight();
  @include remove-tap-highlight();
  cursor:pointer;
  border-top: solid 1px rgba(255,255,255,0.2);
  height:42px;
  line-height: 42px;
  width: max-content;
  .activation-col-separator {
    width: 1px;
    height: 45px;
    background-color: rgba(255,255,255,0.2);
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;
    vertical-align: bottom;
  }
  .activation-col, .activation-col-filter {
    display: inline-block;
    &:first-child {
      width: 260px;
    }
    width: 130px;
    p {
      margin: 0;
      font-family: 'CenturyGothic';
      font-size: $medium;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: normal;
    }
  }
  .activation-col-filter{
    width: 160px;
    padding-right: 10px;
  }
}