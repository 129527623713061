@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.description {
  .containerField {
    @include containerField;
  }

  h1, label, p, textarea {
    color:$black !important;
    span {
      color: $black !important
    }
  }

  .details {
    display: flex;
    .activationAndBaseline {
      flex: 1;
      margin-right: $margin-md;
    }
    .picture {
      flex: 1;
    }
  }

  .separator {
    height: 1px;
    display: block;
    width: 100%;
    background-color: #E5E5E5;
  }

}